import request from "./request";
import CryptoJS from "crypto-js";
import JSEncrypt from "jsencrypt/bin/jsencrypt.min";

const crypto = {
  /**
   * 获取RSA公钥
   */
  getPublic: () => {
    return new Promise((resolve, reject) => {
      request({
        url: "/public/generate/rsa",
        method: "get",
        notEncrypt: true,
      }).then((keyRes) => {
        if (keyRes.data && keyRes.data.publicKey && keyRes.data.expireTime) {
          resolve(keyRes.data);
        } else {
          reject(new Error("获取公钥失败"));
        }
      });
    });
  },

  /**
   * 生产随机字符串
   * @param min
   * @param max
   * @returns {string}
   */
  generateRandomCode: (min = 16, max = 16) => {
    const charset = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    const range = Math.round(Math.random() * (max - min)) + min;
    let randomCode = "";
    for (let i = 0; i < range; i++) {
      randomCode += charset[Math.round(Math.random() * (charset.length - 1))];
    }
    return randomCode;
  },

  /**
   * RSA 加密
   * @param plainText
   * @param key
   */
  encryptByRSA: (plainText, key) => {
    const encrypt = new JSEncrypt(); // 实例化加密对象
    encrypt.setPublicKey(
      "-----BEGIN PUBLIC KEY-----" + key + "-----END PUBLIC KEY-----"
    ); // 设置公钥
    return encrypt.encrypt(plainText); // 加密返回
  },

  /**
   * AES 加密
   * @param plainText
   * @param random
   * @param iv
   * @returns {string}
   */
  encryptByAES: (plainText, random, iv = "E08ADE2699714B87") => {
    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(plainText),
      CryptoJS.enc.Utf8.parse(random),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return encrypted.ciphertext.toString();
  },

  /**
   * AES 解密
   * @param cipherText
   * @param random
   * @param iv
   * @returns {string}
   */
  decryptByAES: function (cipherText, random, iv = "E08ADE2699714B87") {
    const decrypt = CryptoJS.AES.decrypt(
      CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(cipherText)),
      CryptoJS.enc.Utf8.parse(random),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  },
};
export default crypto;
